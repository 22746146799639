<template>
  <section class="ds-container bg">
    <b-container fluid>
      <b-row class="breadcrumbs">
        <router-link to="/configuracoes-da-clinica" tag="span" class="path">
          Configurações da Clínica
        </router-link>
        <ChevronRight class="chevron" />
        <router-link
          :to="`/convenio/${clinicHealthPlanId}`"
          tag="span"
          class="path"
        >
          Editar convênio
        </router-link>
        <ChevronRight class="chevron" />
        <router-link
          :to="`/convenio/${clinicHealthPlanId}/matmed`"
          tag="span"
          class="path"
        >
          Mat/Med
        </router-link>
        <ChevronRight class="chevron" />
        <span class="path">
          Nova configuração
        </span>
      </b-row>
    </b-container>
    <b-container fluid class="ds-content mt-3 p-4">
      <Header 
        :loading="loading"
        :tissMatMedConfig="config"
        @update="openConfigModal"
      />
      <Exceptions 
        :type="config.table"
        :tissMatMedConfigId="configId"
        :clinicHealthPlanId="clinicHealthPlanId"
        @add-exception="addException"
        class="mt-3"
      />
      <hr />
      <Filters 
        :type="config.table"
        :filters="filters" 
        withFraction 
      />
      <Table 
        :expenses="filteredExpenses"
        :type="config.table"
        :priceFormation="config.price_formation"
        @save="saveException"
        @delete="deleteExpenseException"
      />
    </b-container>
    <ConfigModal 
      :clinicHealthPlanId="clinicHealthPlanId"
      :tissMatMedConfig="config"
      :expenseTypesOptions="config.expense_types"
      @onSave="updateConfig"
    />
  </section>
</template>

<script>
import api from '@/modules/tiss/matMed/api'
import unitMeasures from '@/components/Tiss/Data/unitMeasures'

export default {
  name: 'TablePage',
  components: {
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    Header: () => import('@/modules/tiss/matMed/components/config/ConfigHeader'),
    Exceptions: () => import('@/modules/tiss/matMed/components/config/Exceptions'),
    Filters: () => import('@/modules/tiss/matMed/components/tables/Filters'),
    Table: () => import('@/modules/tiss/matMed/components/config/Table'),
    ConfigModal: () => import('@/modules/tiss/matMed/components/healthPlan/ConfigModal'),
  },
  data() {
    return {
      loading: false,
      simplifiedTable: true,
      configId: this.$route.params.configId,
      clinicHealthPlanId: this.$route.params.clinicHealthPlanId,
      config: {},
      expenses: [],
      filteredExpenses: [],
      filters: {
        item: null,
        manufacturer: null,
        status: null,
        fraction: null,
      },
      measures: unitMeasures,
    }
  },
  async created() {
    await this.getConfig()
  },
  methods: {
    addException(expense) {
      const exception = {
        tiss_imported_expense_id: expense.id,
        unitary_value: null,
        unit_measurement: null,
        decrease_or_increase_factor: null,
        active: true,
      }
      this.$set(expense, 'exception', exception)
      this.expenses.push(expense)
    },
    async getConfig() {
      try {
        const { data } = await api.getMatMedConfigs(this.configId)
        this.config = data
        this.expenses = data.expenses ? data.expenses.filter(expense => expense.exception) : []
      } catch (error) {
        this.$toast.error('Houve um erro ao buscar a configuração, contate o suporte se o erro persistir.')
      }
    },
    async saveException(expense) {
      try {
        let res = null

        if (expense?.exception?.id)
          res = await api.updateTissExpenseException(expense.exception.id, expense.exception)
        else
          res = await api.createTissExpenseException(expense.exception)

        if (res.data.id)
          this.$set(expense, 'exception', res.data)

        this.$toast.success('Exceções salvas com sucesso.')
      } catch (error) {
        this.$toast.error('Houve um erro ao salvar as exceções, contate o suporte se o erro persistir.')
      }
    },
    filterFraction(measurentUnitCode, expense) {
      if (expense?.unit_measurement === measurentUnitCode || expense?.exception?.unit_measurement === measurentUnitCode)
        return true

      if (expense?.mat_med?.fraction) {
        const fraction = this.measures.find(
          measure => measure.label.includes(expense?.mat_med?.fraction)
        )

        if (fraction?.value === measurentUnitCode)
          return true
      }

      return false
    },
    getFilteredExpenses() {
      this.filteredExpenses = this.expenses.filter(expense => {
        if (this.filters?.item?.length) {
          if (!expense.name.toLowerCase().includes(this.filters.item.toLowerCase()) && !expense.code.toLowerCase().includes(this.filters.item.toLowerCase()))
            return false
        }
        if (this.filters?.manufacturer?.length && !expense.manufacturer.toLowerCase().includes(this.filters.manufacturer.toLowerCase()))
            return false

        if (this.filters?.status !== null && !!expense.exception?.active !== this.filters.status)
          return false

        if (this.filters?.fraction !== null && !this.filterFraction(this.filters.fraction, expense))
          return false

        return true
      })
    },
    openConfigModal() {
      this.$bvModal.show('config-modal')
    },
    updateConfig(config) {
      this.config = config
    },
    async deleteExpenseException(index) {
      const deletedExpense = this.expenses[index]
      try {
        this.expenses.splice(index, 1)
        if (deletedExpense?.exception?.id) 
          await api.deleteTissExpenseException(deletedExpense?.exception?.id)

        this.$toast.success('Exceção removida com sucesso.')
        this.getFilteredExpenses()
      } catch (err) {
        this.$toast.error('Houve um erro ao remover a exceção, contate o suporte se o erro persistir.')
      }
    },
  },
  watch: {
    filters: {
      handler: 'getFilteredExpenses',
      deep: true,
    },
    expenses: {
      handler: 'getFilteredExpenses',
      deep: true,
    }
  },
}
</script>

<style lang="scss" src="@/modules/tiss/matMed/styles/tiss.scss" />